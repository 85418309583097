import React from 'react';

import Title from './Title';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Item } from './List';

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  text: {
    fontFamily: 'Lato',
    fontSize: 10,
  },
});

const Varia = ({ data }) => (
  <View style={styles.container}>
    <Title>Varia</Title>
    {data && data.map((varia,i) => <Item key={i}>{varia}</Item>)}
  </View>
);

export default Varia;
