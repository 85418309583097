import React from 'react';
import { useQuery,gql } from '@apollo/client';
import { Viewer, PDFDownloader } from './Components';
import { PacmanLoader } from "react-spinners";
import {isMobile} from 'react-device-detect';
const GET_INFO = gql`
  {
    name
    age
    intro
    email
    function
    jobs {
      company
      title
      projects {
        name
        description
        details
      }
      started
      ended
    }
    skills {
      type
      level
      name
    }
    education {
      school
      degree
    }
    varia
    social {
      network
      url
    }
  }
`;

const AppContainer = () => {
  const { data, error, loading } = useQuery(GET_INFO, { fetchPolicy: 'cache-and-network' });
  if (loading) {
    return <div className='loader-container'><PacmanLoader size={50} /></div>;
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  document.title = data.name;
  if(isMobile) {
    return <PDFDownloader data={data} />
  }
  return <Viewer data={data} />;
};

export default AppContainer;
