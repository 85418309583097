import React from 'react';
import Title from './Title';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    height: 165,
  },
  text: {
    fontFamily: 'Lato',
    fontSize: 10,
  },
});

const Intro = ({data}) => (
  <View style={styles.container}>
    <Title>Intro</Title>
    <Text style={styles.text}>{data}</Text>
  </View>
);

export default Intro;
