import React, { Fragment } from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Title from './Title';
import { Item } from './List';

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  school: {
    fontFamily: 'Lato Bold',
    fontSize: 10,
  },
  degree: {
    fontFamily: 'Lato',
    fontSize: 10,
    marginBottom: 10
  },
});

export default ({data}) => (
  <View style={styles.container}>
    <Title>Education</Title>
    {data.map(({school, degree},i) => (
      <Item key={i}>{school}{"\n"}{degree}</Item>
    ))}
  </View>
);
