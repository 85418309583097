import React, { Fragment } from 'react';

import {
  PDFViewer,
  Document,
  Font,
  Page,
  StyleSheet,
  Image,
  View,
  BlobProvider,
} from '@react-pdf/renderer';
import Header from './Header';
import Education from './Education';
import Experience from './Experience';
import Skills from './Skills';
import Intro from './Intro';
import Varia from './Varia';
import Social from './Social';

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
  },
  image: {
    marginBottom: 10,
  },
  leftColumn: {
    flexDirection: 'column',
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  rightColumn: {
    flexDirection: 'column',
    flex: 1,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0,
    },
    paddingTop: 30,
    paddingLeft: 15,
  },
  footer: {
    fontSize: 12,
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 3,
    borderColor: 'gray',
    borderStyle: 'dashed',
    '@media orientation: landscape': {
      marginTop: 10,
    },
  },
  pdfViewer: {
    display: 'block',
    border: 'none',
    height: '100vh',
    width: '100vw',
  },
});

Font.register({
  family: 'Lato',
  src: 'static/Inconsolata-Regular.ttf',
  // src: '//fonts.gstatic.com/s/inconsolata/v15/BjAYBlHtW3CJxDcjzrnZCJ0EAVxt0G0biEntp43Qt6E.ttf',
});
Font.register({
  family: 'Lato Italic',
  src: 'static/Inconsolata-Light.ttf',
  // src: '//fonts.gstatic.com/s/inconsolata/v15/BjAYBlHtW3CJxDcjzrnZCJ0EAVxt0G0biEntp43Qt6E.ttf',
});
Font.register({
  family: 'Lato Bold',
  src: 'static/InconsolataSemiExpanded-Bold.ttf',
  // src: '//fonts.gstatic.com/s/inconsolata/v15/BjAYBlHtW3CJxDcjzrnZCJ0EAVxt0G0biEntp43Qt6E.ttf',
});

export const Resume = ({ data, ...props }) => (
  <Page {...props} style={styles.page}>
    <Header data={data} />
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        <Image
          src="https://media-exp1.licdn.com/dms/image/C4E03AQF9zg4i44tDCQ/profile-displayphoto-shrink_400_400/0/1604305493355?e=1613001600&v=beta&t=s704gEJd112Z6ZrN3aKXq4QQc5bIgVZZ7HNLpRiicC0"
          style={styles.image}
        />
        <Education data={data.education} />
        <Skills data={data.skills} />
        <Varia data={data.varia} />
        <Social data={data.social} />
      </View>
      <View style={styles.rightColumn}>
        <Intro data={data.intro} />
        <Experience data={data.jobs} />
      </View>
    </View>
  </Page>
);

export const Output = ({ data }) => (
  <Document
    author={data.name}
    keywords="awesome, resume, full stack"
    subject="The CV of Jens Verbeken"
    title="Jens Verbeken CV"
    fileName="Jens.pdf"
  >
    <Resume size="A4" data={data} />
  </Document>
);

export const Viewer = (props) => (
  <PDFViewer width={'100vw'} height={'100vh'} style={styles.pdfViewer} fileName={'jens.pdf'}>
    <Output data={props.data} />
  </PDFViewer>
);

export const PDFDownloader = (props) => {
  return (
    <Fragment>
      <BlobProvider
        document={<Output data={props.data} />}
      >
        {({ blob, url, loading }) => {
          var a = document.createElement('a');
          document.body.appendChild(a);
          // a.style = "display: none";
          if (!loading) {
            console.log('url2', url);
            a.href = url;
            a.download = `${props.data.name}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);

            return 'Check je downloads';
          }
          return '';
        }}
      </BlobProvider>
    </Fragment>
  );
};
