import React from 'react';

import Title from './Title';
import List, { SubTextItem } from './List';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
  },
  entryContainer: {
    marginBottom: 10,
  },
  date: {
    fontSize: 11,
    fontFamily: 'Lato Italic',
  },
  detailContainer: {
    flexDirection: 'row',
  },
  detailLeftColumn: {
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
  },
  detailRightColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  bulletPoint: {
    fontSize: 10,
  },
  details: {
    fontSize: 10,
    fontFamily: 'Lato',
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  leftColumn: {
    flexDirection: 'column',
    flexGrow: 9,
  },
  rightColumn: {
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-end',
    justifySelf: 'flex-end',
  },
  title: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  list: {
    marginLeft: 10
  }
});

const ExperienceEntry = ({ company, projects, position, date }) => {
  const title = `${company} | ${position}`;
  return (
    <View style={styles.entryContainer}>
      <View style={styles.headerContainer}>
        <View style={styles.leftColumn}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.date}>{date}</Text>
        </View>
      </View>
      <View style={styles.list} >
      {projects && (
        <List style={styles.list}>
          {projects &&
            projects.map((project, i) => (
              <SubTextItem
                key={i}
                style={styles.detailContainer}
                title={project.name}
              >
                {project.description && project.description.length > 0 && `${project.description}\n`}
                {project.details.map((detail,i) => <Text key={i}>- {detail}{"\n"}</Text>)}
              </SubTextItem>
            ))}
        </List>
      )}
    </View>
    </View>
  );
};

const Experience = ({ data }) => (
  <View style={styles.container}>
    <Title>Experience</Title>
    {data &&
      data.map(({ company, started, ended, projects, title }) => (
        <ExperienceEntry
          company={company}
          date={`${started} - ${ended || '...'}`}
          projects={projects}
          key={company + title}
          position={title}
        />
      ))}
  </View>
);

export default Experience;
