import React from 'react';

import Title from './Title';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Item } from './List';

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  item: {
    flexDirection: 'row',
  },
  text: {
    fontFamily: 'Lato',
    fontSize: 10,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
});

const Social = ({ data }) => (
  <View style={styles.container}>
    <Title>Social</Title>
    {data &&
      data.map((social,i ) => (
        <Item key={i}>{social.url}</Item>
      ))}
  </View>
);

export default Social;
