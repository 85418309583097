import React from 'react';
import ReactDOM from 'react-dom';
import App from './AppContainer';
import './index.css';
import  {ApolloProvider,InMemoryCache, ApolloClient} from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';


const cache = new InMemoryCache();

const endpoint = window.settings.public.graphqlUrl || 'http://localhost:3001/graphql';
const client = new ApolloClient({
  link: ApolloLink.from([
    new HttpLink({
      uri: endpoint,
      fetch,
    }),
  ]),
  cache,
});
const AppContainer = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

ReactDOM.render(<AppContainer />, document.getElementById('root'));
