import React from 'react';

import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  parent: {
    flexDirection: 'column',
    marginBottom: 10,
  },
  item: {
    flexDirection: 'row',
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  itemContent: {
    flex: 1,
    fontSize: 10,
    fontFamily: 'Lato',
  },
  subText: {
    fontSize: 10,
    fontFamily: 'Lato',
    marginLeft: 10
  }
});

const List = ({ children }) => children;

export const Item = ({ subtext, children }) => (
  <View>
    <View style={styles.item}>
      <Text style={styles.bulletPoint}>•</Text>
      <Text style={styles.itemContent}>{children}</Text>
    </View>
    <Text style={styles.subText}>{subtext}</Text>
  </View>
);


export const SubTextItem = ({ title, children }) => (
  <View style={styles.parent}>
    <View style={styles.item}>
      <Text style={styles.bulletPoint}>•</Text>
      <Text style={styles.itemContent}>{title}</Text>
    </View>
    <Text style={styles.subText}>{children}</Text>
  </View>
);

export const ThreeLineItem = ({ title, subtext1, subtext2 }) => (
  <View style={styles.parent}>
    <View style={styles.item}>
      <Text style={styles.bulletPoint}>•</Text>
      <Text style={styles.itemContent}>{title}</Text>
    </View>
    <Text style={styles.subText}>{subtext1}</Text>
    <Text style={styles.subText}>{subtext2}</Text>
  </View>
);

export default List;
