import React from 'react';

import Title from './Title';
import List, { Item } from './List';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import * as R from 'ramda';

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
  },
  title: {
    fontFamily: 'Lato Bold',
    fontSize: 11,
    marginBottom: 10,
  },
  skills: {
    fontFamily: 'Lato',
    fontSize: 10,
    marginBottom: 10,
  },
});

const SkillEntry = ({ name, skills }) => (
  <View style={styles.container}>
    <Text style={styles.title}>{name}</Text>
    <List>
      {skills.map((skill, i) => (
        <Item key={i}>{skill}</Item>
      ))}
    </List>
  </View>
);

const Skills = ({data: skills}) => {
  
  const pairs = R.pipe(R.reduce((memo, skill) => {
    return {
      ...memo,
      [skill.type]: [...memo[skill.type] || [], skill]
    }
  }, {}),R.toPairs)(skills);

  return (
  <View>
    <Title>Skills</Title>
    {pairs.map(([type, values], i) => <SkillEntry
      name={type}
      key={i}
      skills={values.map(value => `${value.name}`)}
    />)}
  </View>
)};

export default Skills;
